<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-snackbar v-model="editFlash" :timeout="4000" top>
      <span>User Edit Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="editFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="addFlash" :timeout="4000" top>
      <span>User Add Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="addFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="deleteFlash" :timeout="4000" top>
      <span>User Delete Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="deleteFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <!-- <v-row>
      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="orange"
          icon="mdi-account-group"
          title=""
          value="+245"
          sub-text="Total Users"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="primary"
          icon="mdi-van-utility"
          title=""
          value="75.521"
          sub-text="Total Drivers"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="info"
          icon="mdi-account-multiple"
          title=""
          value="$ 34,245"
          sub-text="Total Customers"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="success"
          icon="mdi-account-supervisor-circle"
          title=""
          value="184"
          sub-icon-color="red"
          sub-text="Total Admin Staff"
        />
      </v-col>
    </v-row> -->
    <v-data-table
      :headers="columns"
      :items="users"
      item-key="_id"
      class="elevation-1"
      hide-default-footer
      disable-pagination
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col>
              <v-text-field
                @keyup="globalSearch"
                v-model="search"
                label="Search"
                class="mx-4 mt-4"
              ></v-text-field>
            </v-col>
            <v-col>
              <router-link
                v-if="modulePermission.Create"
                :to="{ name: 'Add Admin Staff' }"
              >
                <v-btn color="primary float-right" dark class="mt-4"
                  >Add Staff Member</v-btn
                >
              </router-link>
            </v-col>
          </v-row>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title>Confirm Delete</v-card-title
              ><v-card-text class="text-center"
                >Are you sure to delete record ?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" depressed @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary darken-1"
                  depressed
                  @click="deleteItemConfirm"
                  >Delete</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item._id`]="{ index }">
        {{ numbers[index] }}
      </template>
      <template v-slot:[`item.store_name`]="{ item }">
        <div v-if="item.store_name[0] != null">
          {{ item.store_name[0].name }}
        </div>
      </template>
      <template v-slot:[`item.role_name`]="{ item }">
        <div v-if="item.role_name[0] != null">{{ item.role_name[0].name }}</div>
      </template>
      <template v-slot:[`item.user_type`]="{ item }">
        {{ item.user_type[0].name }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <router-link
          v-if="modulePermission.Update"
          :to="{
            name: 'Edit Admin Staff',
            params: { id: item._id },
          }"
          ><v-icon class="mr-2">mdi-pencil</v-icon></router-link
        >
        <router-link
          v-if="modulePermission.Read"
          :to="{
            name: 'View Admin Staff',
            params: { id: item._id, action: 'view' },
          }"
          ><v-icon class="mr-2">mdi-eye</v-icon></router-link
        >
        <v-icon v-if="modulePermission.Delete" @click="deleteCategory(item)"
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <div class="custom_pagination_design">
      <span>
        Showing <b>{{ startRecord }}</b> to <b>{{ endRecord }}</b> of
        <b>{{ totalRecord }}</b> Records
      </span>
      <v-pagination
        class="float-right"
        v-model="page"
        :length="totalPages"
        total-visible="7"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        @input="handlePageChange"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
export default {
  props: ["edit", "add"],
  data() {
    return {
      moduleName: "Admin Staff",
      modulePermission: [],
      search: "",
      users: [],
      dialogDelete: false,
      deleteRecord: "",
      page: 1,
      totalPages: 0,
      startRecord: 0,
      endRecord: 0,
      totalRecord: 0,
      loading: true,
      numbers: [],
      editFlash: false,
      addFlash: false,
      deleteFlash: false,
    };
  },
  computed: {
    columns() {
      return [
        {
          text: "Actions",
          value: "actions",
          fixed: true,
          sortable: false,
          width: "150px",
        },
        {
          sortable: false,
          text: "Id",
          value: "_id",
          width: "100px",
          sortable: false,
        },
        {
          sortable: false,
          text: "First Name",
          value: "first_name",
          width: "150px",
        },
        {
          sortable: false,
          text: "Last Name",
          value: "last_name",
          width: "150px",
        },
        { sortable: false, text: "Email", value: "email", width: "200px" },
        {
          sortable: false,
          text: "Phone Number",
          value: "phone_number",
          width: "170px",
        },
        {
          sortable: false,
          text: "Store Name",
          value: "store_name",
          width: "150px",
        },
      ];
    },
  },
  methods: {
    getUsers() {
      if (this.search == "") {
        this.search = null;
      }
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "user/admin/" +
            this.search +
            "?page=" +
            this.page
        )
        .then((response) => {
          if (response.status == 200) {
            this.users = response.data.adminUsers.data;
            this.loading = false;
            this.totalPages = response.data.adminUsers.last_page;
            this.startRecord = response.data.adminUsers.from;
            this.endRecord = response.data.adminUsers.to;
            this.totalRecord = response.data.adminUsers.total;
            this.numbers = [];
            for (
              let num = response.data.adminUsers.from;
              num <= response.data.adminUsers.to;
              num++
            ) {
              this.numbers.push(num);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    globalSearch() {
      this.loading = true;
      this.getUsers();
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    deleteItemConfirm() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL + "user/delete/" + this.deleteRecord
        )
        .then((response) => {
          if (response.status == 200) {
            this.dialogDelete = false;
            this.deleteRecord = "";
            this.deleteFlash = true;
            this.getUsers();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteCategory(data) {
      this.dialogDelete = true;
      this.deleteRecord = data._id;
    },
    handlePageChange(value) {
      this.loading = true;
      this.page = value;
      this.getUsers();
    },
  },
  mounted() {
    this.getUsers();
    let permissions = JSON.parse(sessionStorage.getItem("user-permission"));
    this.modulePermission = permissions[this.moduleName];
    if (this.edit) {
      this.editFlash = true;
    }
    if (this.add) {
      this.addFlash = true;
    }
  },
};
</script>

<style>
</style>
